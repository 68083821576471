import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Paper from '@material-ui/core/Paper';
import Place from '@material-ui/icons/Place';

import Header from '../components/Header/header';
import Footer from '../components/Footer/footer';
import SEO from '../components/SEO/SEO';
import { ping } from '../utils/utils.js';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import '../../static/common.scss';

import 'typeface-roboto';

export default ({ data }) => {
  let siteMetadata = data.site.siteMetadata;

  let timeLine = [{
    date: '2022年11月18号',
    content: [
      '腾讯云接入备案成功，站点可以正常访问了。'
    ]
  }, {
    date: '2022年11月06号',
    content: [
      '域名成功迁入腾讯云，站点也转移到了腾讯云的上海节点，接入备案中。'
    ]
  }, {
    date: '2022年11月03号',
    content: [
      '由于云主机到期，域名还在转入腾讯云中，站点暂时关闭。'
    ]
  }, {
    date: '2019年11月02号',
    content: [
      '站点迁回国啦，搬迁至套路云上海节点。'
    ]
  }, {
    date: '2019年10月14号',
    content: [
      '站点升级到Http2.0协议。'
    ]
  }, {
    date: '2019年09月30号',
    content: [
      '整站搬移到vultr洛杉矶节点，国内依旧使用阿里云CDN代理。',
      '放弃WordPress，使用基于React的静态博客生成器Gatsby.js，主题是个人写的 Gatsby-One ，详见页面底部链接。',
      '利用Jenkins配合GitHub Webhooks实现站点自动化构建。'
    ]
  }, {
    date: '2019年08月09号',
    content: ['站点内用到的静态资源文件均存放于image.zhuyuntao.cn虚机上，等待静态博客上线。']
  }, {
    date: '2018年11月05号',
    content: [
      '整站迁移至vultr的西雅图节点，国内访问使用了阿里云CDN代理。',
      '由于访问国外https间歇性连不上，强制https跳转到http。'
    ]
  }, {
    date: '2018年10月14号',
    content: ['站点由http升级到了https。']
  }, {
    date: '2016年07月17号',
    content: ['基于wordpress的个人博客上线，采用了Kratos主题，仍跑于共享虚拟主机之上。']
  }, {
    date: '2015年08月03号',
    content: ['www.zhuyuntao.cn域名注册，阿里云免费共享虚拟主机上线，开始尝试各种玩意。'],
  }];

  // 为该页面重写部分seo配置
  siteMetadata.description = '关于站点 www.zhuyuntao.cn 的建站历程';
  siteMetadata.keywords = 'zhuyuntao,个人博客站点';

  useEffect(() => {
    ping();
  }, []);

  return (
    <div>
      <Helmet>
          <title>{ siteMetadata.siteTitle } - 关于站点</title>
      </Helmet>
      <SEO site={ siteMetadata } />
      <Header { ...siteMetadata } />
      <div className="content-container">
        <div className="article-content">
          <Paper className="blog-post">
            <h1>关于站点</h1>
            <div className="">
              {/* <h2>站点事件</h2> */}
              <VerticalTimeline layout="1-column">
                {
                  timeLine.map((line, ind) => {
                    return (
                      <VerticalTimelineElement
                          key={ ind }
                          className="vertical-timeline-element--work"
                          contentStyle={{ background: '#F5F5F5', color: '#000', borderTop: '3px solid #0097ee' }}
                          contentArrowStyle={{ borderRight: '7px solid #F5F5F5' }}
                          iconStyle={{ background: '#F5F5F5', color: '#000' }}
                          icon={ <Place /> }
                      >
                        <h2 className="vertical-timeline-element-title">{ line.date }</h2>
                        {
                          line.content.map((con, index) => {
                            return (
                              <p key={ index }>
                                {con}
                              </p>
                            );
                          })
                        }
                      </VerticalTimelineElement>
                    );
                  })
                }
              </VerticalTimeline>
            </div>
          </Paper>
        </div>
      </div>
      <Footer { ...siteMetadata } />
    </div>
  );
}
export const query = graphql`
query {
  site {
    siteMetadata {
      description
      siteTitle
      keywords
      imageUrl
      siteUrl
      author
      github
      menus {
        name
        path
      }
      bottomMenus {
        name
        path
      }
    }
  }
}
`;
